export const countryList = [
  { code: 'AF', name: 'Afghanistan', number: '93' },
  { code: 'AL', name: 'Albania', number: '355' },
  { code: 'DZ', name: 'Algeria', number: '213' },
  { code: 'AS', name: 'American Samoa', number: '1-684' },
  { code: 'AD', name: 'Andorra', number: '376' },
  { code: 'AO', name: 'Angola', number: '244' },
  { code: 'AI', name: 'Anguilla', number: '1-264' },
  { code: 'AG', name: 'Antigua and Barbuda', number: '1-268' },
  { code: 'AR', name: 'Argentina', number: '54' },
  { code: 'AM', name: 'Armenia', number: '374' },
  { code: 'AW', name: 'Aruba', number: '297' },
  { code: 'AU', name: 'Australia', number: '61' },
  { code: 'AT', name: 'Austria', number: '43' },
  { code: 'AZ', name: 'Azerbaijan', number: '994' },
  { code: 'BS', name: 'Bahamas (the)', number: '1-242' },
  { code: 'BH', name: 'Bahrain', number: '973' },
  { code: 'BD', name: 'Bangladesh', number: '880' },
  { code: 'BB', name: 'Barbados', number: '1-246' },
  { code: 'BY', name: 'Belarus', number: '375' },
  { code: 'BE', name: 'Belgium', number: '32' },
  { code: 'BZ', name: 'Belize', number: '501' },
  { code: 'BJ', name: 'Benin', number: '229' },
  { code: 'BM', name: 'Bermuda', number: '1-441' },
  { code: 'BT', name: 'Bhutan', number: '975' },
  { code: 'BO', name: 'Bolivia (Plurinational State of)', number: '591' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', number: '599' },
  { code: 'BA', name: 'Bosnia and Herzegovina', number: '387' },
  { code: 'BW', name: 'Botswana', number: '267' },
  { code: 'BR', name: 'Brazil', number: '55' },
  { code: 'IO', name: 'British Indian Ocean Territory (the)', number: '246' },
  { code: 'BN', name: 'Brunei Darussalam', number: '673' },
  { code: 'BG', name: 'Bulgaria', number: '359' },
  { code: 'BF', name: 'Burkina Faso', number: '226' },
  { code: 'BI', name: 'Burundi', number: '257' },
  { code: 'CV', name: 'Cabo Verde', number: '238' },
  { code: 'KH', name: 'Cambodia', number: '855' },
  { code: 'CM', name: 'Cameroon', number: '237' },
  { code: 'CA', name: 'Canada', number: '1' },
  { code: 'KY', name: 'Cayman Islands (the)', number: '1-345' },
  { code: 'CF', name: 'Central African Republic (the)', number: '236' },
  { code: 'TD', name: 'Chad', number: '235' },
  { code: 'CL', name: 'Chile', number: '56' },
  { code: 'CN', name: 'China', number: '86' },
  { code: 'CX', name: 'Christmas Island', number: '61' },
  { code: 'CC', name: 'Cocos (Keeling) Islands (the)', number: '61' },
  { code: 'CO', name: 'Colombia', number: '57' },
  { code: 'KM', name: 'Comoros (the)', number: '269' },
  { code: 'CD', name: 'Congo (the Democratic Republic of the)', number: '243' },
  { code: 'CG', name: 'Congo (the)', number: '242' },
  { code: 'CK', name: 'Cook Islands (the)', number: '682' },
  { code: 'CR', name: 'Costa Rica', number: '506' },
  { code: 'HR', name: 'Croatia', number: '385' },
  { code: 'CU', name: 'Cuba', number: '53' },
  { code: 'CW', name: 'Curaçao', number: '599' },
  { code: 'CY', name: 'Cyprus', number: '357' },
  { code: 'CZ', name: 'Czechia', number: '420' },
  { code: 'CI', name: "Côte d'Ivoire", number: '225' },
  { code: 'DK', name: 'Denmark', number: '45' },
  { code: 'DJ', name: 'Djibouti', number: '253' },
  { code: 'DM', name: 'Dominica', number: '1-767' },
  { code: 'DO', name: 'Dominican Republic (the)', number: '1-809' },
  { code: 'EC', name: 'Ecuador', number: '593' },
  { code: 'EG', name: 'Egypt', number: '20' },
  { code: 'SV', name: 'El Salvador', number: '503' },
  { code: 'GQ', name: 'Equatorial Guinea', number: '240' },
  { code: 'ER', name: 'Eritrea', number: '291' },
  { code: 'EE', name: 'Estonia', number: '372' },
  { code: 'SZ', name: 'Eswatini', number: '268' },
  { code: 'ET', name: 'Ethiopia', number: '251' },
  { code: 'FK', name: 'Falkland Islands (the) [Malvinas]', number: '500' },
  { code: 'FO', name: 'Faroe Islands (the)', number: '298' },
  { code: 'FJ', name: 'Fiji', number: '679' },
  { code: 'FI', name: 'Finland', number: '358' },
  { code: 'FR', name: 'France', number: '33' },
  { code: 'GF', name: 'French Guiana', number: '594' },
  { code: 'PF', name: 'French Polynesia', number: '689' },
  { code: 'GA', name: 'Gabon', number: '241' },
  { code: 'GM', name: 'Gambia (the)', number: '220' },
  { code: 'GE', name: 'Georgia', number: '995' },
  { code: 'DE', name: 'Germany', number: '49' },
  { code: 'GH', name: 'Ghana', number: '233' },
  { code: 'GI', name: 'Gibraltar', number: '350' },
  { code: 'GR', name: 'Greece', number: '30' },
  { code: 'GL', name: 'Greenland', number: '299' },
  { code: 'GD', name: 'Grenada', number: '1-473' },
  { code: 'GP', name: 'Guadeloupe', number: '590' },
  { code: 'GU', name: 'Guam', number: '1-671' },
  { code: 'GT', name: 'Guatemala', number: '502' },
  { code: 'GG', name: 'Guernsey', number: '44' },
  { code: 'GN', name: 'Guinea', number: '224' },
  { code: 'GW', name: 'Guinea-Bissau', number: '245' },
  { code: 'GY', name: 'Guyana', number: '592' },
  { code: 'HT', name: 'Haiti', number: '509' },
  { code: 'VA', name: 'Holy See (the)', number: '379' },
  { code: 'HN', name: 'Honduras', number: '504' },
  { code: 'HK', name: 'Hong Kong', number: '852' },
  { code: 'HU', name: 'Hungary', number: '36' },
  { code: 'IS', name: 'Iceland', number: '354' },
  { code: 'IN', name: 'India', number: '91' },
  { code: 'ID', name: 'Indonesia', number: '62' },
  { code: 'IR', name: 'Iran (Islamic Republic of)', number: '98' },
  { code: 'IQ', name: 'Iraq', number: '964' },
  { code: 'IE', name: 'Ireland', number: '353' },
  { code: 'IM', name: 'Isle of Man', number: '44' },
  { code: 'IT', name: 'Italy', number: '39' },
  { code: 'JM', name: 'Jamaica', number: '1-876' },
  { code: 'JP', name: 'Japan', number: '81' },
  { code: 'JE', name: 'Jersey', number: '44' },
  { code: 'JO', name: 'Jordan', number: '962' },
  { code: 'KZ', name: 'Kazakhstan', number: '7' },
  { code: 'KE', name: 'Kenya', number: '254' },
  { code: 'KI', name: 'Kiribati', number: '686' },
  {
    code: 'KP',
    name: "Korea (the Democratic People's Republic of)",
    number: '850'
  },
  { code: 'KR', name: 'Korea (the Republic of)', number: '82' },
  { code: 'KW', name: 'Kuwait', number: '965' },
  { code: 'KG', name: 'Kyrgyzstan', number: '996' },
  { code: 'LA', name: "Lao People's Democratic Republic (the)", number: '856' },
  { code: 'LV', name: 'Latvia', number: '371' },
  { code: 'LB', name: 'Lebanon', number: '961' },
  { code: 'LS', name: 'Lesotho', number: '266' },
  { code: 'LR', name: 'Liberia', number: '231' },
  { code: 'LY', name: 'Libya', number: '218' },
  { code: 'LI', name: 'Liechtenstein', number: '423' },
  { code: 'LT', name: 'Lithuania', number: '370' },
  { code: 'LU', name: 'Luxembourg', number: '352' },
  { code: 'MO', name: 'Macao', number: '853' },
  { code: 'MG', name: 'Madagascar', number: '261' },
  { code: 'MW', name: 'Malawi', number: '265' },
  { code: 'MY', name: 'Malaysia', number: '60' },
  { code: 'MV', name: 'Maldives', number: '960' },
  { code: 'ML', name: 'Mali', number: '223' },
  { code: 'MT', name: 'Malta', number: '356' },
  { code: 'MH', name: 'Marshall Islands (the)', number: '692' },
  { code: 'MQ', name: 'Martinique', number: '596' },
  { code: 'MR', name: 'Mauritania', number: '222' },
  { code: 'MU', name: 'Mauritius', number: '230' },
  { code: 'YT', name: 'Mayotte', number: '262' },
  { code: 'MX', name: 'Mexico', number: '52' },
  { code: 'FM', name: 'Micronesia (Federated States of)', number: '691' },
  { code: 'MD', name: 'Moldova (the Republic of)', number: '373' },
  { code: 'MC', name: 'Monaco', number: '377' },
  { code: 'MN', name: 'Mongolia', number: '976' },
  { code: 'ME', name: 'Montenegro', number: '382' },
  { code: 'MS', name: 'Montserrat', number: '664' },
  { code: 'MA', name: 'Morocco', number: '212' },
  { code: 'MZ', name: 'Mozambique', number: '258' },
  { code: 'MM', name: 'Myanmar', number: '95' },
  { code: 'NA', name: 'Namibia', number: '264' },
  { code: 'NR', name: 'Nauru', number: '674' },
  { code: 'NP', name: 'Nepal', number: '977' },
  { code: 'NL', name: 'Netherlands (the)', number: '31' },
  { code: 'NC', name: 'New Caledonia', number: '687' },
  { code: 'NZ', name: 'New Zealand', number: '64' },
  { code: 'NI', name: 'Nicaragua', number: '505' },
  { code: 'NE', name: 'Niger (the)', number: '227' },
  { code: 'NG', name: 'Nigeria', number: '234' },
  { code: 'NU', name: 'Niue', number: '683' },
  { code: 'NF', name: 'Norfolk Island', number: '672' },
  { code: 'MP', name: 'Northern Mariana Islands (the)', number: '1' },
  { code: 'NO', name: 'Norway', number: '47' },
  { code: 'OM', name: 'Oman', number: '968' },
  { code: 'PK', name: 'Pakistan', number: '92' },
  { code: 'PW', name: 'Palau', number: '680' },
  { code: 'PS', name: 'Palestine, State of', number: '970' },
  { code: 'PA', name: 'Panama', number: '507' },
  { code: 'PG', name: 'Papua New Guinea', number: '675' },
  { code: 'PY', name: 'Paraguay', number: '595' },
  { code: 'PE', name: 'Peru', number: '51' },
  { code: 'PH', name: 'Philippines (the)', number: '63' },
  { code: 'PL', name: 'Poland', number: '48' },
  { code: 'PT', name: 'Portugal', number: '351' },
  { code: 'PR', name: 'Puerto Rico', number: '1' },
  { code: 'QA', name: 'Qatar', number: '974' },
  { code: 'MK', name: 'Republic of North Macedonia', number: '389' },
  { code: 'RO', name: 'Romania', number: '40' },
  { code: 'RU', name: 'Russian Federation (the)', number: '7' },
  { code: 'RW', name: 'Rwanda', number: '250' },
  { code: 'RE', name: 'Réunion', number: '262' },
  { code: 'BL', name: 'Saint Barthélemy', number: '590' },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    number: '290'
  },
  { code: 'KN', name: 'Saint Kitts and Nevis', number: '1' },
  { code: 'LC', name: 'Saint Lucia', number: '1' },
  { code: 'MF', name: 'Saint Martin (French part)', number: '590' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', number: '508' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', number: '1' },
  { code: 'WS', name: 'Samoa', number: '685' },
  { code: 'SM', name: 'San Marino', number: '378' },
  { code: 'ST', name: 'Sao Tome and Principe', number: '239' },
  { code: 'SA', name: 'Saudi Arabia', number: '966' },
  { code: 'SN', name: 'Senegal', number: '221' },
  { code: 'RS', name: 'Serbia', number: '381' },
  { code: 'SC', name: 'Seychelles', number: '248' },
  { code: 'SL', name: 'Sierra Leone', number: '232' },
  { code: 'SG', name: 'Singapore', number: '65' },
  { code: 'SX', name: 'Sint Maarten (Dutch part)', number: '1' },
  { code: 'SK', name: 'Slovakia', number: '421' },
  { code: 'SI', name: 'Slovenia', number: '386' },
  { code: 'SB', name: 'Solomon Islands', number: '677' },
  { code: 'SO', name: 'Somalia', number: '252' },
  { code: 'ZA', name: 'South Africa', number: '27' },
  { code: 'SS', name: 'South Sudan', number: '211' },
  { code: 'ES', name: 'Spain', number: '34' },
  { code: 'LK', name: 'Sri Lanka', number: '94' },
  { code: 'SD', name: 'Sudan (the)', number: '249' },
  { code: 'SR', name: 'Suriname', number: '597' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen', number: '47' },
  { code: 'SE', name: 'Sweden', number: '46' },
  { code: 'CH', name: 'Switzerland', number: '41' },
  { code: 'SY', name: 'Syrian Arab Republic', number: '963' },
  { code: 'TW', name: 'Taiwan', number: '886' },
  { code: 'TJ', name: 'Tajikistan', number: '992' },
  { code: 'TZ', name: 'Tanzania, United Republic of', number: '255' },
  { code: 'TH', name: 'Thailand', number: '66' },
  { code: 'TL', name: 'Timor-Leste', number: '670' },
  { code: 'TG', name: 'Togo', number: '228' },
  { code: 'TK', name: 'Tokelau', number: '690' },
  { code: 'TO', name: 'Tonga', number: '676' },
  { code: 'TT', name: 'Trinidad and Tobago', number: '1' },
  { code: 'TN', name: 'Tunisia', number: '216' },
  { code: 'TR', name: 'Turkey', number: '90' },
  { code: 'TM', name: 'Turkmenistan', number: '993' },
  { code: 'TC', name: 'Turks and Caicos Islands (the)', number: '1' },
  { code: 'TV', name: 'Tuvalu', number: '688' },
  { code: 'UG', name: 'Uganda', number: '256' },
  { code: 'UA', name: 'Ukraine', number: '380' },
  { code: 'AE', name: 'United Arab Emirates (the)', number: '971' },
  {
    code: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    number: '44'
  },
  { code: 'US', name: 'United States of America (the)', number: '1' },
  { code: 'UY', name: 'Uruguay', number: '598' },
  { code: 'UZ', name: 'Uzbekistan', number: '998' },
  { code: 'VU', name: 'Vanuatu', number: '678' },
  { code: 'VE', name: 'Venezuela (Bolivarian Republic of)', number: '58' },
  { code: 'VN', name: 'Viet Nam', number: '84' },
  { code: 'VG', name: 'Virgin Islands (British)', number: '1' },
  { code: 'VI', name: 'Virgin Islands (U.S.)', number: '1' },
  { code: 'WF', name: 'Wallis and Futuna', number: '681' },
  { code: 'EH', name: 'Western Sahara', number: '212' },
  { code: 'YE', name: 'Yemen', number: '967' },
  { code: 'ZM', name: 'Zambia', number: '260' },
  { code: 'ZW', name: 'Zimbabwe', number: '263' },
  { code: 'AX', name: 'Åland Islands', number: '358' }
];
